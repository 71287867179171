@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;
    --primary: 220.9 39.3% 11%;
    --primary-foreground: 210 20% 98%;
    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;
    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;
    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;
    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 224 71.4% 4.1%;
    --radius: 0.3rem;
  }

  .dark {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;
    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;
    --popover: 224 71.4% 4.1%;
    --popover-foreground: 210 20% 98%;
    --primary: 210 20% 98%;
    --primary-foreground: 220.9 39.3% 11%;
    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;
    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 216 12.2% 83.9%;
  }
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 600;
  background-color: white;
}

.main-content {
  margin-top: 60px; /* Adjust based on the height of your header */
}

.alert {
  position: relative;
  z-index: 500; /* Ensure it's below the header */
}

.table-container {
  position: relative;
  z-index: 400; /* Ensure it's below the alert */
}

.custom-input {
  outline: none !important;
  box-shadow: none !important;
}

.custom-input:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}


@media (max-width: 600px) {
  .pagination-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .pagination-item {
    margin: 0 2px;
  }
}

.special-gradient {
  background: linear-gradient(to right, #9f7aea 50%, #4299e1);
  -webkit-background-clip: text;
  color: transparent;
  font-weight: bold;
}

.link-gradient {
  background: linear-gradient(to right, #9f7aea 50%, #4299e1);
  -webkit-background-clip: text;
  color: transparent;
  text-decoration: underline;
  font-weight: bold;
  display: inline;
  }
  
  .link-gradient:hover {
  color: #6b46c1;
  background: none;
  text-decoration: underline;
  font-weight: bold;
  }

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

body {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}
